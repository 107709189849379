/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import { WMICAddressDetails } from 'wmic-pe-components-platform-react';
import metadata from './WMICPAPolicyAIDetailView.metadata.json5';
import messages from './WMICPAPolicyAIDetailView.messages'


function WMICPAPolicyAIDetailView({ value: additionalInterest, additionalInterestVM }) {
   
    const [isAICompanyVisible, setAICompanyVisible] = useState(false);

    const overrideProps = {
        '@field': {
            parentNode: additionalInterestVM,
            readOnly: true
        },
        policyNewAICompanyContainer: {
            visible: isAICompanyVisible 
        },
    };

    const resolvers = {
        resolveComponentMap: {
            WMICAddressDetails
        },
    };

    return (
        <ViewModelForm
            model={additionalInterestVM}
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}


export default WMICPAPolicyAIDetailView;
