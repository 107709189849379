import React from 'react';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { Position } from 'wmic-pe-portals-utils-js';
import metadata from './WMICIterableDriverComponent.metadata.json5';
import styles from './WMICIterableDriverComponent.module.scss';
import './WMICIterableDriverComponent.messages'

function WMICIterableDriverComponent(props) {
    const { driver } = props;

    const overrides = {
        '@field': {
            parentNode: driver,
            readOnly: true,
            labelPosition: Position.TOP
        },
        accidentWaiverPremium: {
            visible: driver.displayAccidentWaiverPremium
        },
        convictionWaiverPremium: {
            visible: driver.displayConvictionWaiverPremium
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            model={driver}
            overrideProps={overrides}
            uiProps={metadata.componentContent}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

WMICIterableDriverComponent.propTypes = {
    value: PropTypes.shape({}).isRequired
};

export default WMICIterableDriverComponent;
