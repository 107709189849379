import { defineMessages } from 'react-intl';

export default defineMessages({
    driverNumber: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.driver-number',
        defaultMessage: '#'
    },
    driverName: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.driver-name',
        defaultMessage: 'Name'
    },
    assignment: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.assignment',
        defaultMessage: 'Assignment'
    },
    ownerOfVehicle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.owner-of-vehicle',
        defaultMessage: 'Owner of Vehicle #'
    },
    principalDriverOfVehicle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.principal-driver-of-vehicle',
        defaultMessage: 'Principal driver of vehicle #'
    },
    occasionalDriverOfVehicle: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.occasional-driver-of-vehicle',
        defaultMessage: 'Occasional driver of vehicle #'
    },
    gridLevel: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.grid-level',
        defaultMessage: 'Grid level'
    },
    accidentWaiverStatus: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.accident-waiver-status',
        defaultMessage: 'Accident Waiver Status'
    },
    accidentWaiverPremium: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.accident-waiver-premium',
        defaultMessage: 'Accident Waiver Premium'
    },
    convictionWaiverPremium: {
        id: 'wmic.pe-capability-quoteandbind-common-react.pages.iterable-driver-component.convictional-waiver-premium',
        defaultMessage: 'Convictional Waiver Premium'
    }
})
