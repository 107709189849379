import React, { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { WMICCreditConsentUtil, CONSTANTS } from 'wmic-pe-portals-utils-js';

import metadata from './WMICPolicyInsuredPNIAfterRPCCreditConsent.metadata.json5';

function WMICPolicyInsuredPNIAfterRPCCreditConsent(props) {
    const { policyVM, selectedInsured } = props;

    const isPNI = useMemo(
        () => _.get(selectedInsured, 'isPrimaryInsured.value'),
        [selectedInsured]
    );

    const isCreditInfoWithdrawalConsentApplicable = useMemo(() => {
        const consentOption = (_.get(selectedInsured, 'creditConsentReceived.value') && _.get(selectedInsured, 'creditConsentReceived.value.code')) || _.get(selectedInsured, 'creditConsentReceived.value');
        const rateAsOfDate = _.get(policyVM, `latestPeriod.rateAsOfDate.value`)
        const baseState = _.get(selectedInsured, 'address.state.value.code')
        const lob = _.get(policyVM, "lob.value.code")
        const initVehicles = _.get(policyVM, 'lobs.personalAuto.vehicleDTOs.value')
        return WMICCreditConsentUtil.isCreditInfoWithdrawalConsentApplicable(consentOption, rateAsOfDate, baseState, lob, initVehicles);
    }, [selectedInsured, policyVM]);

    
    const consentReceivedYes = useMemo(() => isPNI && _.get(selectedInsured, 'creditConsentReceived.value.code') === CONSTANTS.CREDIT_CONSENT_CODES.YES, [isPNI, selectedInsured])
    const consentReceivedNo = useMemo(() => isPNI && _.get(selectedInsured, 'creditConsentReceived.value.code') === CONSTANTS.CREDIT_CONSENT_CODES.NO, [isPNI, selectedInsured])
    

    const overrideProps = {
        '@field': {
            parentNode: selectedInsured,
            readOnly: true,
        },
        creditConsentReceived: {
            value: _.get(selectedInsured, 'creditConsentReceived.value.code'),
        },
        creditConsentDeclinedDate: {
            visible: consentReceivedNo
        },
        creditConsentWithdrawalForm:{
            visible: isCreditInfoWithdrawalConsentApplicable
        },
        creditConsentDate: {
            value: _.get(selectedInsured, 'creditConsentDate.value'),
            visible: consentReceivedYes
        },
        creditConsentFormReceived:{
            visible: consentReceivedYes
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={selectedInsured}
            overrideProps={overrideProps}
        />
    );
}

WMICPolicyInsuredPNIAfterRPCCreditConsent.propTypes = {
    policyVM: PropTypes.any.isRequired,
    selectedInsured: PropTypes.any.isRequired
};

export default WMICPolicyInsuredPNIAfterRPCCreditConsent;
