function splitByComma(oldString, newString) {
    if (oldString === ""){
        oldString += newString;
    } else if (oldString !== newString) {
        oldString = oldString + ', ' + newString;
    }
    return oldString;
};

function getNameByCode(typeList, item, propertyName) {
    const itemField = item[propertyName];
    if (itemField && itemField !== null) {
        const code = typeList.getCode(itemField);
        if (code && code !== null) {
            return code.name;
        }
    }
    return '';
};

function processedDriverAttributes(driver, viewModelService) {
    driver.availableAssignmentTypeList = getNameByCode(viewModelService.productMetadata.get('pc').types.getTypelist('PDAssignment_WMIC'), driver.value, 'assignment_WMIC');
    driver.accidentWaiverStatus = getNameByCode(viewModelService.productMetadata.get('pc').types.getTypelist('PADriverWaiverStatus_WMIC'), driver.value, 'accidentWaiverStatus_WMIC');
    driver.gridStepName = getNameByCode(viewModelService.productMetadata.get('pc').types.getTypelist('GridStep_WMIC'), driver.value, 'currentGridStep_WMIC');
}

function getMinorConvictionWaiverDriverEndorsement(waiverEndorsements, driver) {
    if(waiverEndorsements) {
        return waiverEndorsements.find((driverWaiverEndor) => driverWaiverEndor.driverPublicID === driver.publicID);
    }
}

function addVehicleDriversType(vehiclesList, driver, isPolicyChangeOrRenewal) {
    const newDriver = driver;
    newDriver.ownerOfVehicle = "";
    newDriver.principalDriverOfVehicle = "";
    newDriver.occasionalDriverOfVehicle = "";

    vehiclesList.forEach((veh) => {
        const vehicle = isPolicyChangeOrRenewal ? veh.vehicle : veh;
        vehicle.registeredOwners_WMIC.value.filter((registeredOwner) => registeredOwner.policyContactPublicID === newDriver.publicID.value)
            .forEach(() => {
                newDriver.ownerOfVehicle = splitByComma(newDriver.ownerOfVehicle, vehicle.vehicleNumber_WMIC.value);
            });

        vehicle.drivers.value.filter((vehicleDriver) => vehicleDriver.driverPublicID_WMIC === newDriver.publicID.value).map((vehDriver) => {
            vehDriver.driverClassType_WMIC = newDriver.value.driverClassHistory_WMIC && newDriver.value.driverClassHistory_WMIC.length > 0
                ? newDriver.value.driverClassHistory_WMIC[0]?.driverClassType_WMIC
                : '';
            if (vehDriver.driverType_WMIC === 'principal') {
                newDriver.principalDriverOfVehicle = splitByComma(newDriver.principalDriverOfVehicle, vehicle.vehicleNumber_WMIC.value);
            }
            if (vehDriver.driverType_WMIC === 'occasional') {
                newDriver.occasionalDriverOfVehicle = splitByComma(newDriver.occasionalDriverOfVehicle, vehicle.vehicleNumber_WMIC.value);
            }
        });
    });

    return newDriver;
}

export default {
    addVehicleDriversType: addVehicleDriversType,
    getMinorConvictionWaiverDriverEndorsement: getMinorConvictionWaiverDriverEndorsement,
    processedDriverAttributes: processedDriverAttributes
};
