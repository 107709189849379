import { defineMessages } from 'react-intl';

export default defineMessages({
    addAdditionalInterest: {
        id: 'wmic-pe-capability-gateway-quoteandbind-ho-react.AdditionalInterests.Add',
        defaultMessage: 'Add Additional Interest'
    },
    aiPerson: {
        id: 'wmic-pe-capability-gateway-quoteandbind-ho-react.AdditionalInterests.Person',
        defaultMessage: 'Person'
    },
    aiCompany: {
        id: 'wmic-pe-capability-gateway-quoteandbind-ho-react.AdditionalInterests.Company',
        defaultMessage: 'Company'
    },
    descriptionOfInterestOption: {
        id: 'wmic-pe-capability-gateway-quoteandbind-ho-react.AdditionalInterests.DescriptionOfInterestOther',
        defaultMessage: 'Describe Other Interest'
    },
    descriptionOfInterestOther: {
        id: 'quoteandbind.ho.views.ho-your-home.Additional Interest Description',
        defaultMessage: 'Describe Other Interest'
    },
    descriptionOfInterestOtherType: {
        id: 'quoteandbind.ho.views.ho-your-home.Additional Interest Description Type',
        defaultMessage: 'Describe Other Interest Type'
    },
    additionalInterestType: {
        id: 'wmic-pe-capability-gateway-quoteandbind-ho-react.AdditionalInterests.AdditionalInterestType',
        defaultMessage: 'Additional Interest Type'
    },
    companyContactDetails: {
        id: 'quoteandbind.ho.views.ho-your-home.CompanyContactDetails',
        defaultMessage: 'Company Contact Details'
    },
    addNewCompany: {
        id: 'quoteandbind.pa.directives.templates.pa-additional-interests.AddNew',
        defaultMessage: "Add a new Company"
    },
    addNewCompanyText: {
        id: "quoteandbind.ho.views.ho-your-home.addNewCompany",
        defaultMessage: 'If you cannot find the company you are looking for you may add a new company below.'
    },
    companyName: {
        id: "quoteandbind.views.ho-your-home.additional-interest.AI Company Name",
        defaultMessage: "Name"
    },
    province : {
        id : "quoteandbind.ho.views.ho-your-home.Province",
        defaultMessage : "Province",
    },
    postalCode: {
        id: "quoteandbind.ho.views.ho-your-home.ZIP Code",
        defaultMessage: "Postal Code"
    },
    MortgageeNumber : {
        id : "quoteandbind.ho.views.ho-your-home.Mortgagee #",
        defaultMessage : "Mortgagee #",
    },
    removeAdditionalInterestShort: {
        id: 'quoteandbind.ho.views.ho-your-home.Remove Interest Short',
        defaultMessage: 'Remove Additional Interest'
    },
    removeAdditionalInterestLong: {
        id: 'quoteandbind.ho.views.ho-your-home.Remove Interest Long',
        defaultMessage: 'Are you sure you want to remove the additional interest from the list?'
    },
    descriptionOfInterest: {
        id: 'quoteandbind.ho.views.ho-your-home.Description of Interest',
        defaultMessage: 'Description of Interest'
    },
    doiType: {
        id: 'quoteandbind.ho.views.ho-your-home.DOI Type',
        defaultMessage: 'DOI Type'
    },
    aiType: {
        id: 'quoteandbind.ho.views.ho-your-home.Type',
        defaultMessage: 'Type'
    },
    effectiveDate: {
        id: 'quoteandbind.ho.views.ho-your-home.Additional Interest Effective Date',
        defaultMessage: 'Effective Date'
    },
    expiryDate: {
        id: 'quoteandbind.ho.views.ho-your-home.Additional Interest Expiry Date',
        defaultMessage: 'Expiry Date'
    },
    aiPersonalInfoTitle: {
        id: 'quoteandbind.ho.views.ho-your-home.Personal Info',
        defaultMessage: 'Personal Info'
    },
    aiMailingAddressEqualPNI: {
        id: 'quoteandbind.ho.views.ho-your-home.Same Mailing Address',
        defaultMessage: 'Mailing Address same as Home Address of Primary Named Insured?'
    },
    cancelAddCompanyButton: {
        id: 'gateway.views.new-quote-account-search.Cancel and Search Again',
        defaultMessage: 'Cancel & Search Again'
    }
});
