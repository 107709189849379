import React, { useContext } from 'react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';

import metadata from './WMICHOConstructionHeatingUpgradeComponent.metadata.json5';
import messages from './WMICHOConstructionHeatingUpgradeComponent.messages';

function WMICHOConstructionHeatingUpgradeComponent (props) {
    const {
        id,
        heatingUpgradeVM,
        setHeatingUpgradeVM,
        showErrors,
        readOnly: pReadOnly
    } = props;

    const { onValidate: setComponentValidation} = useValidation(id);
    const viewModelService = useContext(ViewModelServiceContext);

    const overrideProps = {
        '@field': {
            parentNode: heatingUpgradeVM,
            readOnly: pReadOnly
        }
    }

    const writeValue = (value, path) => {
        const newHeatingUpgradeVM = viewModelService.clone(heatingUpgradeVM);
        _.set(newHeatingUpgradeVM, path, value);
        setHeatingUpgradeVM(newHeatingUpgradeVM);
    }

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={heatingUpgradeVM}
            onModelChange={setHeatingUpgradeVM}
            overrideProps={overrideProps}
            onValueChange={writeValue}
            onValidationChange={setComponentValidation}
            showErrors={showErrors}
        />
    );

}

export default WMICHOConstructionHeatingUpgradeComponent;