/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
import { Flex } from '@jutro/layout';
import { ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { CONSTANTS } from 'wmic-pe-portals-utils-js';
import { useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { WMICDropdownMenuButton, WMICListView } from 'wmic-pe-components-platform-react';
import AdditionalInterestDetailComponent from './WMICHOAdditionalInterestDetailComponent';
import messages from './WMICHOAdditionalInterests.messages';
import styles from './WMICHOAdditionalInterests.module.scss';


const getExistingAIDisplayName = (existingAI) => {
    if (existingAI.policyAdditionalInterest.subtype.value === CONSTANTS.Person
        && (existingAI.policyAdditionalInterest.firstName.value
            || existingAI.policyAdditionalInterest.lastName.value)) {
        return `${existingAI.policyAdditionalInterest.firstName.value} ${existingAI.policyAdditionalInterest.lastName.value}`;
    }
    if (existingAI.policyAdditionalInterest.subtype.value === CONSTANTS.Company
        && existingAI.policyAdditionalInterest.contactName.value) {
        return existingAI.policyAdditionalInterest.contactName.value;
    }
    return '';
}

const displayMortgageeNum = (item) => {
    return item.mortgageeNumber.value ?? '';
};

function WMICHOAdditionalInterests(props) {
    const {
        id,
        riskView,
        onRiskViewChange,
        jobVM,
        onValidate,
        isEditMode,
        category,
        showErrors: pShowErrors,
        canEdit
    } = props;

    const viewModelService = useContext(ViewModelServiceContext);
    const translator = useContext(TranslatorContext);
    const { showConfirm } = useWizardModals();
    const [showErrors, setShowErrors] = useState(false);

    const minDate = useMemo(() => {
        const currentDate = new Date();
        return {
            day: currentDate.getDate() - CONSTANTS.BOT_PARAM_CONFIG.MAXIMUM_LEGAL_AGE,
            month: currentDate.getMonth(),
            year: currentDate.getFullYear()
        };
    }, []);

    const maxDate = useMemo(() => {
        const currentDate = new Date();
        return {
            day: currentDate.getDate() - CONSTANTS.BOT_PARAM_CONFIG.MINIMUM_LEGAL_AGE,
            month: currentDate.getMonth(),
            year: currentDate.getFullYear()
        };
    }, []);

    const createNewInsured = useCallback((subType) => {
        const primaryAddress = {
            country: CONSTANTS.COUNTRY.CA,
            addressType: CONSTANTS.MAILING_ADDRESS
        };
        const contact = {
            subtype: subType,
            minDOB: minDate,
            maxDOB: maxDate,
            primaryAddress: primaryAddress
        };
        return contact;
    }, [maxDate, minDate]);

    const toCreate = useCallback((subtype) => {
        const contact = createNewInsured(subtype);
        const additionalInterest = {
            additionalInterestCategory: category,
            policyAdditionalInterest: viewModelService.create(
                contact,
                'pc',
                'wmic.edge.ca.capabilities.policycommon.accountcontact.dto.AccountContactDTO'
            ).value,
            mailSeparateNotices: true,
            validateDescriptionOfInterest: true,
            validateAdditionalInterestType: true
        };
        const newVM = viewModelService.create(
            additionalInterest,
            'pc',
            'wmic.edge.ca.capabilities.policyjob.lob.AdditionalInterestDTO_WMIC'
        );
        riskView.additionalInterests.value.push(newVM.value);
        return newVM;
    }, [category, createNewInsured, viewModelService]);

    const areAdditionalInterestsValid = (additionalInterests) => additionalInterests?.aspects?.valid && additionalInterests?.aspects?.subtreeValid

    const onSaveAI = useCallback(() => {
        if (areAdditionalInterestsValid(riskView.additionalInterests)) {
            onRiskViewChange(riskView);
            return riskView;
        }
        setShowErrors(true);
    }, [riskView, onRiskViewChange]);

    const onDeleteAI = useCallback(async (item, index) => {
        const response = await showConfirm({
            title: translator(messages.removeAdditionalInterestShort),
            message: translator(messages.removeAdditionalInterestLong)
        })

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            riskView.additionalInterests.value.splice(index, 1);
            riskView.value.isUnderEditing = true;
            onRiskViewChange(riskView);
            return true;
        }
    }, [onRiskViewChange, riskView, showConfirm, translator]);

    const renderAddAIButton = useCallback(({ isEditing, onClick }) => (
        <Flex className="gw-mb-6" justifyContent="left">
            <WMICDropdownMenuButton
                id="addNewAdditionalInterestButton"
                type="secondary"
                size="medium"
                buttonText={messages.addAdditionalInterest}
                alignRight
                menuClassName={styles.newAIDropdownMenuButton}
                onItemClick={onClick}
                items={[
                    { id: 'newPerson', text: translator(messages.aiPerson), icon: 'mi-person', code: CONSTANTS.Person },
                    { id: 'newCompany', text: translator(messages.aiCompany), icon: 'mi-home', code: CONSTANTS.Company },
                ]}
                disabled={isEditing}
            />
        </Flex>
    ), [translator]);

    return (
        <WMICListView
            id={id}
            clickable
            startOpen={false}
            readOnly={!isEditMode}
            editEnabled={false}
            canEdit={canEdit}
            value={_.orderBy(_.get(riskView, `additionalInterests.children`, []), 'value.mortgageeNumber', 'asc')}
            VMData={[
                {
                    headerText: translator({ id: "endorsement.ho.directives.templates.ho-additional-interests-edit.Name", defaultMessage: "Name" }),
                    getData: getExistingAIDisplayName
                },
                {
                    headerText: translator({ id: "wmic-pe-capability-gateway-quoteandbind-ho-react.AdditionalInterests.AdditionalInterestType", defaultMessage: "Type" }),
                    path: 'additionalInterestType'
                },
                {
                    headerText: translator(messages.MortgageeNumber),
                    getData: displayMortgageeNum
                }
            ]}
            detailViewComponent={AdditionalInterestDetailComponent}
            detailViewComponentProps={{
                category: category,
                jobVM,
                riskView
            }}
            renderAddButton={renderAddAIButton}
            onValidate={onValidate}
            toCreate={toCreate}
            toUndoCreate={() => {
                const additionalInterests = _.get(riskView.value, 'additionalInterests');
                additionalInterests.splice(additionalInterests.length - 1, 1);
                _.set(riskView.value, 'additionalInterests', additionalInterests);
                onRiskViewChange(riskView);
            }}
            onSave={onSaveAI}
            onDelete={onDeleteAI}
            showErrors={showErrors || pShowErrors}
        />
    );
}

WMICHOAdditionalInterests.propTypes = {
    id: PropTypes.string.isRequired,
    onRiskViewChange: PropTypes.func,
    jobVM: PropTypes.shape({}).isRequired,
    onValidate: PropTypes.func,
    showErrors: PropTypes.bool,
    riskView: PropTypes.shape({}),
    category: PropTypes.string.isRequired,
    risk: PropTypes.shape({}).isRequired,
    isEditMode: PropTypes.bool,
    canEdit: PropTypes.func
};

WMICHOAdditionalInterests.defaultProps = {
    onValidate: undefined,
    riskView: {},
    onRiskViewChange: undefined,
    isEditMode: false,
    showErrors: false,
    canEdit: () => true
};

export default WMICHOAdditionalInterests;
