/* eslint-disable unicorn/filename-case */
import React, { useCallback, useEffect, useContext, useState } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { useValidation } from '@xengage/gw-portals-validation-react'
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { useWizardModals, useDocumentTitle, WMICWizardSubmissionPage } from 'wmic-pe-portals-wizard-components-ui';
import { WMICRichTextUtil, WMICUserAccessUtil, CONSTANTS, WMICValidationUtil, FlowStepId, WMICVariousUtil } from 'wmic-pe-portals-utils-js';
import _ from 'lodash';
import { WMICErrorHandler } from 'wmic-pe-capability-quoteandbind-common-react';

import { WMICScheduledItemComponent } from 'wmic-pe-capability-gateway-common-ho-react';

import { messages as commonMessages } from 'wmic-pe-capability-gateway-quoteandbind-common-react';
import metadata from './WMICHOScheduledItemsPage.metadata.json5';
import messages from './WMICHOScheduledItemsPage.messages';

function WMICHOScheduledItemsPage(props) {
    const { wizardData: submissionVM, updateWizardData } = props;
    const { isComponentValid, onValidate, initialValidation} = useValidation('WMICHOScheduledItemsPage');
    const { setWizardLoading } = useWizardModals();
    const { authUserData, authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const { showConfirm } = useWizardModals();
    const viewModelService = useContext(ViewModelServiceContext);
    const { LoadSaveService } = useDependencies('LoadSaveService');

    const [showErrors, setShowErrors] = useState(false);
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);

    const SCHEDULED_ITEMS_PATH = 'lobData.homeowners.coverables.scheduledItems';
    const MAX_NUMBER_DESCRIPTION_CHARS = 100;

    useDocumentTitle('Scheduled Items', submissionVM);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canCreateSubmission(authUserData.roles));
    }, [authUserData.roles]);

    useEffect(() => {
        submissionVM.value.flowStepId_WMIC = FlowStepId.HO_SCHEDULED_ITEMS;
    }, [submissionVM.value]);

    const onRemoveScheduledItem = async (si, index) => {
        const response = await showConfirm({
            title: messages.removeScheduledItemShort,
            message: messages.removeScheduledItemLong,
        });

        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const allScheduledItems = _.get(submissionVM, `${SCHEDULED_ITEMS_PATH}.value`, []);

            allScheduledItems.splice(index, 1);
            _.set(submissionVM, `${SCHEDULED_ITEMS_PATH}.value`, allScheduledItems);
            updateWizardData(submissionVM);
        }

        return _.noop();
    };

    const onAddNewScheduledItem = () => {
        const newScheduledItemVM = viewModelService.create(
            { additionalInterests: [] },
            'pc', 'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.scheduleditems.HoScheduledItemsDTO',
            submissionVM.aspects.context()
        );

        setShowErrors(false);
        _.set(submissionVM, "isEditingSI.value", true)
        submissionVM.lobData.homeowners.coverables.scheduledItems.value.push(newScheduledItemVM.value);
        updateWizardData(submissionVM)

        return newScheduledItemVM;
    };

    const onSaveScheduledItem = useCallback(() => {
        _.set(submissionVM, "isEditingSI.value", false);
        updateWizardData(submissionVM);
        setShowErrors(false);

        return true;
    }, [updateWizardData, submissionVM]);

    const getScheduledItemDescription = (item) => {
        const description = _.get(item, 'description.value', '');

        return description.length > MAX_NUMBER_DESCRIPTION_CHARS ? description.substring(0, MAX_NUMBER_DESCRIPTION_CHARS) : description;
    }

    const onNext = useCallback(async () => {
        if(!isComponentValid){
            setShowErrors(true);
            return false;
        }

        try {
            setWizardLoading(true);

            const newSubmissionVM = viewModelService.clone(submissionVM);

            _.unset(newSubmissionVM.value, 'bindData');

            newSubmissionVM.value = await LoadSaveService.syncInitialHOOfferings(newSubmissionVM.value, authHeader);

            newSubmissionVM.value.flowStepId_WMIC = FlowStepId.HO_SCHEDULED_ITEMS;
            submissionVM.value = await LoadSaveService.updateDraftSubmissionWithLOBData(
                newSubmissionVM.value,
                authHeader
            );

            if (WMICValidationUtil.hasDtoValidationErrors(submissionVM, FlowStepId.HO_SCHEDULED_ITEMS)) {
                WMICVariousUtil.scrollToTop();
                return false;
            }

            return submissionVM;
        } catch (error) {
            WMICErrorHandler.processAsModal(error);

            return submissionVM;
        } finally {
            setWizardLoading(false);
        }
    }, [LoadSaveService, authHeader, setWizardLoading, submissionVM, viewModelService, isComponentValid]);

    const overrideProps = {
        scheduledItemsDataList: {
            startOpen: false,
            clickable: true,
            readOnly: isReadOnlyUser,
            showErrors,
            VMData: [
                {
                    headerText: translator(messages.scheduledItemType),
                    path: 'scheduledItemTypeCode'
                },
                {
                    headerText: translator(messages.scheduledItemDescription),
                    path: 'description',
                    getData: (item) => getScheduledItemDescription(item)
                },
                {
                    headerText: translator(messages.scheduledItemLimit),
                    path: 'limit'
                }
            ],
            detailViewComponent: WMICScheduledItemComponent,
            detailViewComponentProps: {
                jobVM: submissionVM
            },
            onValidate,
            toCreate: onAddNewScheduledItem,
            toUndoCreate: () => {
                const scheduledItems = _.get(submissionVM.value, SCHEDULED_ITEMS_PATH);

                scheduledItems.splice(scheduledItems.length-1, 1);
                _.set(submissionVM.value, SCHEDULED_ITEMS_PATH, scheduledItems);
                _.set(submissionVM, "isEditingSI.value", false);
                updateWizardData(submissionVM);
            },
            onSave: onSaveScheduledItem,
            onDelete: onRemoveScheduledItem
        },
        scheduledItemsOptionalContainer: {
            visible: _.get(submissionVM, `${SCHEDULED_ITEMS_PATH}.children`, []).length === 0
        },
        scheduledItemsOptionalText: {
            content: WMICRichTextUtil.translateRichText(translator(messages.wmicHoScheduledItemsOptional)),
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICScheduledItemComponent
        }
    };

    return (
        <WMICWizardSubmissionPage
            onNext={onNext}
            skipWhen={initialValidation}
            cancelLabel={translator(commonMessages.saveAndExit)}
            flowStepId={FlowStepId.HO_SCHEDULED_ITEMS}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                showErrors={showErrors}
                componentMap={resolvers.resolveComponentMap}
            />
        </WMICWizardSubmissionPage>
    );
}

WMICHOScheduledItemsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    ...wizardProps
};

export default WMICHOScheduledItemsPage;
