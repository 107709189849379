/* eslint-disable no-secrets/no-secrets */
import React, { useCallback, useEffect, useContext, useState } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { withViewModelService, ViewModelForm, ViewModelServiceContext } from '@xengage/gw-portals-viewmodel-react';
import { useAuthentication } from '@xengage/gw-digital-auth-react';
import { useDependencies } from '@xengage/gw-portals-dependency-react';
import { wizardProps } from 'wmic-pe-portals-custom-wizard-react';
import { WMICWizardChangeOrRenewalPage, useWizardModals } from 'wmic-pe-portals-wizard-components-ui';
import { useValidation } from '@xengage/gw-portals-validation-react';
import _ from 'lodash';
import { WMICScheduledItemComponent } from 'wmic-pe-capability-gateway-common-ho-react';
import { WMICUserAccessUtil, CONSTANTS } from 'wmic-pe-portals-utils-js';
import { messages as commonMessages } from 'wmic-pe-capability-gateway-policyrenewal-common-react';
import metadata from './WMICHOPolicyRenewalScheduledItemsPage.metadata.json5';
import messages from './WMICHOPolicyRenewalScheduledItemsPage.messages';

const SCHEDULED_ITEMS_PATH = 'lobData.homeowners.coverables.scheduledItems';
const MAX_NUMBER_DESCRIPTION_CHARS = 100;

function WMICHOPolicyRenewalScheduledItemsPage(props) {
    const {
        wizardData: policyRenewalVM,
        updateWizardData
    } = props;

    const translator = useContext(TranslatorContext);
    const { showConfirm } = useWizardModals();
    const { authUserData, authHeader } = useAuthentication();
    const { setWizardLoading } = useWizardModals();
    const { RenewalService } = useDependencies('RenewalService');
    const viewModelService = useContext(ViewModelServiceContext);

    const { isComponentValid, onValidate, initialValidation } = useValidation('WMICHOPolicyRenewalScheduledItemsPage');

    const [showErrors, setShowErrors] = useState(false);
    const [isReadOnlyUser, setReadOnlyUser] = useState(true);

    useEffect(() => {
        setReadOnlyUser(!WMICUserAccessUtil.canEditPolicyRenewal(authUserData.roles));
    }, [authUserData.roles]);


    const onAddNewScheduledItem = () => {
        // To invalidate Quote step when adding a new scheduled item
        updateWizardData(policyRenewalVM);

        const newScheduledItemVM = viewModelService.create(
            { additionalInterests: [] },
            'pc', 'wmic.edge.ca.capabilities.policyjob.lob.homeownersHOE.coverables.dto.scheduleditems.HoScheduledItemsDTO',
            policyRenewalVM.aspects.context()
        );
        setShowErrors(false);
        _.set(policyRenewalVM, "isEditingSI.value", true)
        policyRenewalVM.lobData.homeowners.coverables.scheduledItems.value.push(newScheduledItemVM.value);
        return newScheduledItemVM;
    };

    const onSaveScheduledItem = useCallback(() => {
        _.set(policyRenewalVM, "isEditingSI.value", false);
        updateWizardData(policyRenewalVM);
        setShowErrors(false);
        return true;
    }, [updateWizardData, policyRenewalVM]);

    const onRemoveScheduledItem = async (si, index) => {
        const response = await showConfirm({
            title: messages.removeScheduledItemShort,
            message: messages.removeScheduledItemLong,
        });
        if (response === CONSTANTS.MODAL_RESULT.CONFIRM) {
            const allScheduledItems = _.get(policyRenewalVM, `${SCHEDULED_ITEMS_PATH}.value`, []);
            allScheduledItems.splice(index, 1);
            _.set(policyRenewalVM, `${SCHEDULED_ITEMS_PATH}.value`, allScheduledItems);
            updateWizardData(policyRenewalVM);
        }
        return _.noop();
    };

    const getScheduledItemDescription = (item) => {
        const description = _.get(item, 'description.value', '');
        return description.length > MAX_NUMBER_DESCRIPTION_CHARS ? description.substring(0, MAX_NUMBER_DESCRIPTION_CHARS) : description;
    }

    const onCancelScheduledItem = () => {
        _.set(policyRenewalVM, 'isEditingPage.value', false);
        updateWizardData(policyRenewalVM);
    }

    const onNext = useCallback(async () => {
        if(!isComponentValid){
            setShowErrors(true);
            return false;
        }

        try {
            setWizardLoading(true);
            const newPolicyRenewalVM = _.cloneDeep(policyRenewalVM.value);

            policyRenewalVM.value = await RenewalService.saveRenewal(
                [newPolicyRenewalVM],
                authHeader
            );
            return policyRenewalVM;
        } finally {
            setWizardLoading(false);
        }
    }, [RenewalService, authHeader, isComponentValid, policyRenewalVM, setWizardLoading]);

    const overrideProps = {
        scheduledItemsDataList: {
            startOpen: false,
            clickable: true,
            readOnly: isReadOnlyUser,
            showErrors,
            VMData: [
                {
                    headerText: translator(messages.scheduledItemType),
                    path: 'scheduledItemTypeCode'
                },
                {
                    headerText: translator(messages.scheduledItemDescription),
                    path: 'description',
                    getData: (item) => getScheduledItemDescription(item)
                },
                {
                    headerText: translator(messages.scheduledItemLimit),
                    path: 'limit'
                }
            ],
            detailViewComponent: WMICScheduledItemComponent,
            detailViewComponentProps: {
                jobVM: policyRenewalVM
            },
            onValidate,
            toCreate: onAddNewScheduledItem,
            toUndoCreate: () => {
                const scheduledItems = _.get(policyRenewalVM.value, SCHEDULED_ITEMS_PATH);
                scheduledItems.splice(scheduledItems.length-1, 1);
                _.set(policyRenewalVM.value, SCHEDULED_ITEMS_PATH, scheduledItems);
                _.set(policyRenewalVM, "isEditingSI.value", false);
                updateWizardData(policyRenewalVM);
            },
            onSave: onSaveScheduledItem,
            onDelete: onRemoveScheduledItem,
            onCancel: onCancelScheduledItem,
            showCancelModal: true
        },
        scheduledItemsOptionalContainer: {
            visible: _.get(policyRenewalVM, `${SCHEDULED_ITEMS_PATH}.children`, []).length === 0
        }
    };

    const resolvers = {
        resolveComponentMap: {
            WMICScheduledItemComponent
        }
    };

    return (
        <WMICWizardChangeOrRenewalPage
            onNext={onNext}
            cancelLabel={translator(commonMessages.saveAndExit)}
            isSkipping={initialValidation}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={policyRenewalVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                componentMap={resolvers.resolveComponentMap}
                showErrors={showErrors}
            />
        </WMICWizardChangeOrRenewalPage>
    );
}

WMICHOPolicyRenewalScheduledItemsPage.propTypes = wizardProps;

export default withViewModelService(WMICHOPolicyRenewalScheduledItemsPage);
